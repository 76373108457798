

input, label {
  display:block;
}


:root {
  --my-green: rgb(0, 209, 0);
  --my-purple: rgb(167, 0, 167);
}

.mypurple {
  color:  var(--my-purple);
}

.mygreen {
  color: var(--my-green);
}